import axios from "../request"

// 获取服务器的版本信息
export const getAppver = () => axios.get('https://management.noitom.com.cn/admin2019/appver')

//account

//微信快捷登录
export const authWechat = (code) => axios.post(`/oa/user/auth/${code}/wechat`)
// 获取注册验证码图片
export const getCaptchaImage = () => axios.get(`/oa/public/captcha-image`)
// 使用短信验证码登录
export const LoginSms = (data) => axios.post(`/oa/public/login/sms`,data)
// 发送登录短信验证码
export const sendLoginSms = (data) => axios.post(`/oa/public/send-sms/login`,data)

// 获取处方列表
export const prescriptionList = (params) => axios.get(`/oa/prescription`,{
    params:params
})
// 处方详情
export const prescriptionDetail = (id) => axios.get(`/oa/prescription/${id}`)
// 视频详情
export const videoDetail = (id,preId) => axios.get(`/oa/prescriptionTrainVideoRel/${id}/${preId}`)
// 疼痛反馈
export const feedback = (id,data) => axios.post(`/oa/userHurtRecord/${id}`,data)
//用户详情
export const getUser = (id) => axios.get(`/oa/user/${id}`)

// 疼痛反馈
export const partialUpdateUser = (id,data) => axios.post(`/oa/user/${id}/partial`,data)




// 修改密码
export const resetPwd = (data) => axios.post(`/public/reset-pwd`,data)
// 修改用户信息
export const editModifyData = (userId,data) => axios.post(`/users/${userId}/edit`,data)
// 文章列表
export const articleList = () => axios.get(`/articles`)
//文章详情
export const articleDetail = (articleId) => axios.get(`/articles/${articleId}`)
// 修改密码
export const editPwd = (userId,data) => axios.post(`/users/${userId}/edit-pwd`,data)

//文章收藏
export const articlesCollect = (articleId) => axios.post(`/articles/${articleId}/collect`)




//获取资料列表
export const getResources = (params) => axios.get('/resources', {params})

//获取资料标签
export const getResourcesLabels = () => axios.get('/public/labels')

//获取资料类型
export const getResourceType = () => axios.get('/public/resouce-type')

//资料收藏
export const resourcesCollect = (resourceId) => axios.post(`/resources/${resourceId}/collect`)

//下载资料
export const downloadResource = (resourceId) => axios.get(`/resources/${resourceId}`, {
    responseType: 'blob',
    headers: { 'content-type': 'application/stream' }
})

//获取收藏的资料列表
export const getResourcesCollect = () => axios.get('/resources/collection')

//获取收藏的文章列表
export const getArticlesCollect = () => axios.get('/articles/collection')
//获取报告
export const getReportData = () => axios.get('/userData/report-data')
//完善个人信息
export const updateUser = (userId,userData) => axios.post(`/oa/user/${userId}`,userData)
//获取所有机构信息
export const orgAll = () => axios.get('/oa/public/org-all')
//根据编码获取机构信息
export const getOrgByCodeHttp = (orgCode) => axios.get(`/oa/public/${orgCode}/org-code`)
//设置通用处方
export const addCommonPre = (userId) => axios.post(`/oa/user/${userId}/prescription/common`)
//提交Par-Q问卷
export const addParQ = (parQData) => axios.post('/oa/par-q',parQData)
//提交HOOS-12 髋关节问卷
export const addHipQ = (hipQData) => axios.post('/oa/hip-q',hipQData)
//提交KOOS-12 膝关节调查问卷
export const addKneeQ = (kneeQData) => axios.post('/oa/knee-q',kneeQData)
//提交Keele STarT Back  Screening Tool （Keele STarT背部筛查工具）腰背调查问卷
export const addBackQ = (backQData) => axios.post('/oa/back-q',backQData)
//兑换码兑换
export const payByRedeemCode = (prescriptionId,redeemCode) => axios.post(`/oa/payment/${prescriptionId}/redeem-code/${redeemCode}`)
//微信支付
export const payWechat = (prescriptionId) => axios.post(`/oa/payment/${prescriptionId}/wechat`)
export const wechatNotify = (data) => axios.post(`/oa/payment/wechat/notify`,data)
/** 训练部位 */
export const getActionPartsHttp = () => axios.get(`/oa/common/action-part`)
/** 运动方式 */
export const getActionModesHttp = () => axios.get(`/oa/common/action-mode`)
