<template>
  <div class="Prescription">
    <div class="title">训练方案</div>
    <div v-if="this.preListData && this.preListData.length > 0">
      <div v-for="(item,i) in preListData" :key="i" @click="toPreDetail(item)" :id="'pre'+item.id">
        <div :class="item.id == preId?'preData active':'preData'">
          <img :src="item.imgUrl" class="pic">
          <h3>{{ item.title }}</h3>
          <div v-if="item.payStatus !== 2" class="price">
            <span><span>¥</span>{{ item.price }}</span><s>原价¥{{ item.originalPrice }}</s>
          </div>
          <div class="to-be-paid" v-if="item.payStatus == 0">待支付</div>
          <div class="desc">
            <span class="label">{{ item.typeName }}</span>
            <span>{{ item.period }}周</span>
            <span>每周{{ item.freqWeekly }}次</span>
          </div>
          <p class="remark">{{ item.remark }}</p>
        </div>
    </div>
    </div>

    <div v-else>
      <img src="@/assets/img/wtoagzh.png" class="noData">
       <p class="ins">扫码关注公众号</p>
       <p class="ins">获取运动方案</p>
    </div>


    <!-- 支付对话框 -->
    <van-popup v-model="payTypeDialog" v-if="payTypeDialog" position="bottom">
      <div class="line"></div>
      <van-radio-group v-model="payType">
        <van-cell-group>
          <van-cell title="兑换码支付" clickable @click="payType = 'redeemCode'">
            <template #icon>
              <img src="@/assets/img/redeem_code.svg" alt="">
            </template>
            <template #right-icon>
              <van-radio name="redeemCode" />
            </template>
          </van-cell>
          <van-cell title="微信支付" clickable @click="payType = 'wechat'">
            <template #icon>
              <img src="@/assets/img/wechat.svg" alt="">
            </template>
            <template #right-icon>
              <van-radio name="wechat" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-button block type="primary" @click="toPay">下一步</van-button>
    </van-popup>
    <WechatDialog ref="WechatDialog" @loadData="getPreList" :prescriptionId="prescriptionId" v-if="WechatDialog" ></WechatDialog>
    <RedeemCodeDialog ref="RedeemCodeDialog" @loadData="getPreList" :prescriptionId="prescriptionId" v-if="RedeemCodeDialog" ></RedeemCodeDialog>
  </div>
</template>
<script>

import { prescriptionList, authWechat,getOrgByCodeHttp } from "@/api/getData"
import WechatDialog from "@/views/Pay/WechatPay.vue";
import RedeemCodeDialog from "@/views/Pay/RedeemCode.vue";
export default {
  name: "Prescription",
  components: { WechatDialog,RedeemCodeDialog},
  //声明数据源
  data() {
    return {
      preListData: [],
      payType:'wechat',
      payTypeDialog:false,
      prescriptionId:'',
      payData: {},
      WechatDialog:false,
      RedeemCodeDialog:false,
      preId:'',
      orgCode:''
    }
  },
  methods: {
    //去支付
    toPay(){
      if(this.payType === 'redeemCode'){//兑换码
        this.payTypeDialog = false
        this.RedeemCodeDialog = true
        // this.$router.push({
        //   path: "/redeem-code",
        //   query: {
        //     preId: this.prescriptionId
        //   }
        // })
      }else if(this.payType === 'wechat'){//微信支付
        this.payTypeDialog = false
        this.WechatDialog = true
        // this.$router.push({
        //   path: "/wechat-pay",
        //   query: {
        //     preId: this.prescriptionId
        //   }
        // })
      }
    },
    GetParam(url, code) {
      url = url + "";
      let regstr = "/(\\?|\\&)" + code + "=([^\\&]+)/";
      let reg = eval(regstr);
      //eval可以将 regstr字符串转换为 正则表达式
      let result = url.match(reg);
      if (result && result[2]) {
        return result[2];
      }
    },
    toweichat() {
      //判断是否是微信浏览器
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      {
        //判断是否是微信浏览器
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          let code = this.GetParam(window.location.href, "code");
          if (code) {
            //这里可以进行后续操作 已经能出现code了 一般是调用接口发送给后台这个code码以换取openid或者token码
            authWechat(code).then(res => {
              if (res.data.token) {
                this.$store.commit('SET_vToken', res.data.token)
                this.$store.commit('SET_vUserId', res.data.userId)
                this.$store.commit('SET_vUsername', res.data.name)
                this.$store.commit('SET_vOrgData', {id: res.data.orgId,name:''})
                if(res.data.name){                     
                  this.getPreList()          
                }else{
                  this.$router.push({path: "/supplement"}) 
                }                
              } else {
                this.$store.commit('SET_WECHATID', res.data.wechatId)
                this.$store.commit('SET_WECHATIMG', res.data.wechatUrl)
                this.$router.push({
                  path: "/login-sms"
                })
              }
            })
          } else {
            //获取当前页面的地址
            let local = `${process.env.VUE_APP_CLIENT_URL}`;//'http://wctest.ng.noitom.com.cn/#/';
            //调接微信官方生成授权登录的url appid必须填写
            //wx51177af5a0aaad8d
            let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + `${process.env.VUE_APP_WECHAR_APPID}` + "&redirect_uri="
              + encodeURIComponent(local)
              + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
            //这一步是跳转这上方定义的url
            window.location.href = url;
          }
        } else {
          this.$message.error('请使用微信浏览器')
        }
      }
    },

    getPreList() {
      this.payTypeDialog = false
      this.RedeemCodeDialog = false
      this.WechatDialog = false
      let params = {
        userId: this.vUserId,
        terminal: 1
      }
      prescriptionList(params).then(res => {
        this.preListData = res.data
        if (this.preId) {
          this.$nextTick(() => {
            let pre = document.querySelector(`#pre${this.$route.query.preId}`)
            console.log(pre.offsetTop)
            if (pre.offsetTop > 300) {
              document.querySelector('.Prescription').scrollTop = pre.offsetTop - 24
            }
          })
        }
      })
    },
    toPreDetail(item) {
      this.prescriptionId = item.id;
     // if(item.payStatus == 0){
      //  this.payTypeDialog = true
        //this.$message.error('请先支付')
     // }else{
        this.$router.push({
          path: "/prescription-detail",
          query: {
            preId: this.prescriptionId
          }
        })
     // }      
    },
    getOrgByCode(){
      getOrgByCodeHttp(this.orgCode).then(res => {
        console.log("orgData",res.data)
        if(res.data){
          this.$store.commit('SET_vOrgData', res.data)
          this.toweichat()
        }else{
          this.$message.error('未找到该机构信息')
          return false;
        }
        
      }).catch(error => {
        console.log(error)
      })    
    }
  },
  mounted() {
    this.preId = this.$route.query.preId;    
    if (this.vToken) {
      if(this.username){                     
        this.getPreList()          
      }else{
        this.$router.push({path: "/supplement"}) 
      } 
    } else {
      this.orgCode = this.$route.query.orgCode;
      if(this.orgCode){
        this.getOrgByCode()
      }else{
        this.toweichat()
      }      
    }
  }
}
</script>

<style lang="scss" scoped>
.Prescription {
  height: calc(100vh - 0.58rem);
  padding: 0 0.16rem 0.58rem;
  background: #F9FFFF url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  .title {
    font-size: 0.18rem;
    font-weight: 500;
    padding: 0.24rem 0;
  }
  .noData{
    display: block;
    width: 100%;
    margin: 0 auto;
    width:2rem;
    margin-top: 1.1rem;
  }
  .ins{
    color: #9BA5A5;
    font-size: 0.14rem;
    line-height: 0.24rem;
    text-align: center;
  }
  .preData {
    position: relative;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0 0.04rem 0.1rem 0 rgba(8, 131, 131, 0.09);
    margin-bottom: 0.24rem;
    .price {
      margin: 0.24rem 0 0.2rem 0.2rem;

      span {
        font-size: 0.24rem;
        font-weight: 500;
        color: #fa5151;

        span {
          font-size: 0.12rem;
          font-weight: 400;
        }
      }

      s {
        margin-left: 8px;
        font-size: 12px;
        color: #a4aeae;
      }
    }
    .pic {
      display: block;
      width: 100%;
      height: 1.37rem;
      object-fit: cover;
    }
    h3 {
      font-size: 0.18rem;
      font-weight: 500;
      position: absolute;
      top: 0.99rem;
      left: 0.2rem;
      color: #ffffff;
    }
    .to-be-paid {
      width: 0.72rem;
      height: 0.28rem;
      text-align: center;
      line-height: 0.28rem;
      font-size: 0.14rem;
      color: #f5fdff;
      position: absolute;
      top: -0.11rem;
      left: -0.09rem;
      border-radius: 0.25rem;
      background-color: #fa5151;
      border: 0.02rem solid #FFFFFF;
    }
    .desc {
      font-size: 0.14rem;
      border-bottom: 1px solid #eaf1f1;
      padding: 0 0 0.24rem 0.2rem;
      color: #1c2121;
      .label, .label + span {
        margin-right: 0.16rem;
      }
    }
    .remark {
      font-size: 0.14rem;
      line-height: 0.24rem;
      color: #909090;
      padding: 0.24rem 0.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 0.48rem;
    }
  }
  .active{
    border: 0.01rem solid #24bbd3;
  }

  .van-popup {
    height: 2.16rem;

    .line {
      width: 0.3rem;
      height: 0.04rem;
      margin: 0.1rem auto;
      border-radius: 0.1rem;
      background-color: #d8d8d8;
    }

    .van-cell {
      height: 0.56rem;
      padding: 0 0.16rem;
      align-items: center;

      > img {
        width: 0.24rem;
        margin-right: 0.16rem;
      }
    }

    .van-button {
      width: 3.43rem !important;
      height: 0.48rem;
      border-radius: 0.4rem;
      margin: 0.13rem auto 0;

      .van-button__text {
        font-weight: 700;
      }
    }
  }
}
</style>