<template>
  <div class="home">
    <router-view></router-view>
    <div class="tabbar" v-if="!$route.meta.hideTab">
      <div class="menu_list">
        <router-link replace to="/joint" :class="$route.path === '/joint' ? 'is-active' : ''">
          <img :src="require(`@/assets/img/download_${$route.path === '/joint' ? 'active' : 'icon'}.svg`)" alt="">
          <p>开始评测</p>
        </router-link>
      </div>
      <div class="menu_list">
        <router-link replace to="/prescription-list" :class="$route.path === '/Prescription' ? 'is-active' : ''">
          <img :src="require(`@/assets/img/Prescription_${$route.path === '/Prescription' ? 'active' : 'icon'}.svg`)" alt="">
          <p>训练方案</p>
        </router-link>
      </div>
      <div class="menu_list">
        <router-link replace to="/user" :class="$route.path === '/user' ? 'is-active' : ''">
          <img :src="require(`@/assets/img/my_${$route.path === '/user' ? 'active' : 'icon'}.svg`)" alt="">
          <p>个人中心</p>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
    }
  },
  updated() {
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.home {
  > div:first-child {
    overflow-y: auto;
  }

  .tabbar {
    height: 0.58rem;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 66;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    border-top: 2px solid rgba(0, 0, 0, 0.08);
    color: #3D3D3D;
    background-color: #fff;

    a.is-active {
      color: #24BBD3;
    }

    img {
      display: block;
      width: 0.28rem;
      margin: 0.06rem auto 0;
    }
    p {
      font-size: 0.1rem;
      margin-top: 0.06rem;
    }
  }
}
</style>