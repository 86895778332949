<template>
  <div class="my">
    <div>
      <video controls webkit-playsinline="true" :src="videoDetailData.videoUrl" playsinline="true" x5-video-player-type="h5-page" x5-video-orientation="landscape|portrait" id="upvideo" :poster="videoDetailData.coverImg">
      </video>
      <div class="detailDes">
        <h2 class="title">{{videoDetailData.name}}</h2>
        <div class="detailNum">
          <div class="numBox">
            <p class="num"><b>{{videoDetailData.motionNum}}</b> 次</p>
            <p class="numTitle">次数</p>
          </div>
          <div class="numBox">
            <p class="num"><b>{{videoDetailData.groupNum}}</b> 组</p>
            <p class="numTitle">组数</p>
          </div>
          <div class="numBox">
            <p class="num"><b>{{videoDetailData.groupInterval}}</b> 秒</p>
            <p class="numTitle">间隔时长</p>
          </div>
        </div>
        <div class="label">
          <span>{{switchPartType(videoDetailData.partTypeId)}}</span>
          <span>{{switchActionType(videoDetailData.actionTypeId)}}</span>
        </div>
        <p class="remark" :class="textMore ? 'remark': 'remark textHidden'">{{videoDetailData.remark}}</p>
        <p class="more" @click="showMore">{{textMore ? '收起' : '点击查看更多'}}</p>
      </div>
    </div>

    <div class="detailDes">
      <!-- <h2 class="title">疼痛反馈</h2> -->
      <img src="@/assets/img/feedbackBtn.png" class="feedbackBtn" @click="submit">
      <p class="feedbackTit">点击此处反馈疼痛</p>
    </div>

    <!-- <div class="handle">
      <el-button :disabled="!videoDetailData.previousId" @click="previous">上一步</el-button>
      <el-button :disabled="!videoDetailData.nextId" @click="next">下一步</el-button>
    </div> -->
    <div class="handle" v-if="!videoDetailData.previousId && videoDetailData.nextId">
      <img src="@/assets/img/prevdisBtn.png" class="prevBtn">
      <div class="progress">
        {{videoDetailData.currentNum}}/{{videoDetailData.nums}}
      </div>
      <img src="@/assets/img/nextBtn.png" class="nextBtn" @click="next">
    </div>

    <div class="handle" v-if="videoDetailData.nextId && videoDetailData.previousId">
      <img src="@/assets/img/prevBtn.png" class="prevBtn" @click="previous">
      <div class="progress">
        {{videoDetailData.currentNum}}/{{videoDetailData.nums}}
      </div>
      <img src="@/assets/img/nextBtn.png" class="nextBtn" @click="next">
    </div>

    <div class="begin" v-if="!videoDetailData.nextId">
      <el-button @click="toList">完成训练</el-button>
    </div>


    <!-- 疼痛反馈 -->
    <van-popup v-model="hurtLevelDialog" position="bottom">
      <div class="line"></div>
      <div class="popup-title">疼痛反馈</div>
      <div class="slider-wrap">
        <van-slider v-model="hurtLevel" min="1" max="10" />
        <div class="split-line"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div class="marks">
          <div class="marks-text">1</div>
          <div class="marks-text">2</div>
          <div class="marks-text">3</div>
          <div class="marks-text">4</div>
          <div class="marks-text">5</div>
          <div class="marks-text">6</div>
          <div class="marks-text">7</div>
          <div class="marks-text">8</div>
          <div class="marks-text">9</div>
          <div class="marks-text">10</div>
        </div>
      </div>
      <div class="tips"><span>无疼痛</span><span>疼痛</span></div>
      <van-button block round type="primary" @click="toSubmitHurt">提交疼痛</van-button>
    </van-popup>
     <!-- 支付对话框 -->
     <van-popup v-model="payTypeDialog" v-if="payTypeDialog" position="bottom" class="pay-popup">
      <div class="line"></div>
      <van-radio-group v-model="payType">
        <van-cell-group>
          <van-cell title="兑换码支付" clickable @click="payType = 'redeemCode'">
            <template #icon>
              <img src="@/assets/img/redeem_code.svg" alt="">
            </template>
            <template #right-icon>
              <van-radio name="redeemCode" />
            </template>
          </van-cell>
          <van-cell title="微信支付" clickable @click="payType = 'wechat'">
            <template #icon>
              <img src="@/assets/img/wechat.svg" alt="">
            </template>
            <template #right-icon>
              <van-radio name="wechat" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-button block type="primary" @click="toPay">下一步</van-button>
    </van-popup>
    <WechatDialog ref="WechatDialog" @loadData="loadVideoDatail" :prescriptionId="prescriptionId" v-if="WechatDialog" ></WechatDialog>
    <RedeemCodeDialog ref="RedeemCodeDialog" @loadData="loadVideoDatail" :prescriptionId="prescriptionId" v-if="RedeemCodeDialog" ></RedeemCodeDialog>
  </div>
</template>
<script>
import { 
  videoDetail,
  feedback,
  getActionPartsHttp,
  getActionModesHttp } from '@/api/getData'
import WechatDialog from "@/views/Pay/WechatPay.vue";
import RedeemCodeDialog from "@/views/Pay/RedeemCode.vue";
export default {
  name: "PrescriptionTrainVideo",
  components: { WechatDialog,RedeemCodeDialog},
  data() {
    return {
      videoDetailData: {},
      textMore: false,
      hurtLevel: 1,
      hurtLevelDialog:false,
      imgsrc:'',
      payType:'wechat',
      payTypeDialog:false,
      prescriptionId:'',
      WechatDialog:false,
      RedeemCodeDialog:false,
      actionPartData:[],
      actionModeData:[],
    
    }
  },
  mounted() {    
    this.getActionModes()
    this.getActionParts()
    this.prescriptionId  = this.$route.query.preId
    this.getVideoDetail(this.$route.query.videoId)
  },
  methods: {
    //获取运动方式
    getActionModes(){
       getActionModesHttp().then(({data})=>{
          this.actionModeData = data
       })
    },
    //获取训练部位
    getActionParts(){
      getActionPartsHttp().then(({data})=>{
          this.actionPartData = data
       })
    },
    //去支付
    toPay(){
      if(this.payType === 'redeemCode'){//兑换码
        this.payTypeDialog = false
        this.RedeemCodeDialog = true

      }else if(this.payType === 'wechat'){//微信支付
        this.payTypeDialog = false
        this.WechatDialog = true
      }
    },
    toList() {
      this.$router.push({
        path: "/prescription-detail",
        query: {
          preId: this.$route.query.preId
        }
      })
    },
    previous() {
      this.$router.push({
        path: "/prescription-train-video",
        query: {
          videoId: this.videoDetailData.previousId,
          preId: this.$route.query.preId
        }
      })
      // location.reload();
      this.getVideoDetail(this.videoDetailData.previousId)
    },
    next() {
      this.$router.replace({
        path: "/prescription-train-video",
        query: {
          videoId: this.videoDetailData.nextId,
          preId: this.$route.query.preId
        }
      })
      // location.reload();
      this.getVideoDetail(this.videoDetailData.nextId)
    },
    goBack() {
      this.$router.replace({ path: '/prescription-detail', query: { preId: this.$route.query.preId } })
    },
    toSubmitHurt(){
      if(this.hurtLevel <= 0){
        this.$message.error('请选择疼痛等级')
        return false;
      }
      let params = {
        level: this.hurtLevel,
        prescriptionId:this.prescriptionId
      }
      feedback(this.videoDetailData.currentId,params).then(res => {
        this.$message.success('反馈成功')
        this.hurtLevelDialog = false
      }).catch(error => {
        console.log(error)
      })
    },
    //疼痛反馈
    submit() {
      this.hurtLevelDialog = true
      // this.$confirm('请确认操作', '疼痛反馈', {
      //   cancelButtonText: '取消',
      //   confirmButtonText: '确定',
      //   center: true,
      //   showClose: false
      // }).then(() => {
      //   feedback(this.videoDetailData.currentId).then(res => {
      //     this.$message.success('反馈成功')
      //   }).catch(error => {
      //     console.log(error)
      //   })
      // }).catch(() => {
      // });
    },
    //展示更多备注
    showMore() {
      this.textMore = !this.textMore
    },
    //部位转换
    switchPartType(partTypeId) {
      for (var i in this.actionPartData) {
        if (partTypeId === this.actionPartData[i].value) {
          return this.actionPartData[i].msg;
        }
      }
      return "-";
    },
    //动作转换
    switchActionType(actionTypeId) {
      for (var i in this.actionModeData) {
        if (actionTypeId === this.actionModeData[i].value) {
          return this.actionModeData[i].msg;
        }
      }
      return "-";
    },
    loadVideoDatail(){
      this.payTypeDialog = false
      this.RedeemCodeDialog = false
      this.WechatDialog = false
      this.getVideoDetail(this.$route.query.videoId)
    },
    //获取资料标签
    getVideoDetail(videoId) {
      videoDetail(videoId,this.prescriptionId).then(res => {        
        if(res.data.needPay && res.data.currentNum > 3){
          this.$message.success('支付解锁全部训练')
          this.payTypeDialog = true          
        }else{
          this.videoDetailData = res.data
        }
      }).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.my {
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  video {
    width: 100%;
    height: 2.1rem;
  }

  .detailDes {
    width: 3.43rem;
    margin: 0.16rem auto;
    position: relative;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(8, 131, 131, 0.09);
    padding-bottom: 0.16rem;
    overflow: hidden;
    .title {
      padding: 0 0.16rem;
      height: 0.48rem;
      border-bottom: 1.5px solid #f5f5f5;
      line-height: 0.48rem;
    }
    .detailNum {
      display: flex;
      margin-left: 0.16rem;
      padding: 0.24rem 0;
      .numBox {
        margin-right: 0.4rem;
        .num {
          font-size: 0.1rem;
          color: #24bbd3;
          b {
            font-size: 0.28rem;
          }
        }
        .numTitle {
          font-size: 0.1rem;
          color: #a8b0b0;
          margin-top: 0.12rem;
        }
      }
    }
    .label {
      margin: 0 0.16rem 0.12rem;
      span {
        display: inline-block;
        margin-right: 0.1rem;
        padding: 8px;
        border-radius: 4px;
        background: rgba(36, 187, 211, 0.06);
        color: #24bbd3;
        font-size: 0.14rem;
        line-height: 1;
      }
    }
    .remark {
      font-size: 0.14rem;
      line-height: 0.24rem;
      color: #9da6a6;
      margin: 0 0.16rem;
    }
    .textHidden {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 0.48rem;
    }
    .more {
      margin-top: 0.16rem;
      margin-left: 0.16rem;
      font-size: 0.14rem;
      line-height: 0.24rem;
      color: #24bbd3;
    }
    .feedbackBtn {
      display: block;
      width: 0.67rem;
      margin: 0.3rem auto 0.14rem;
    }
    .feedbackTit {
      font-size: 0.14rem;
      line-height: 0.24rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0.1rem;
    }
  }
  .handle {
    // .el-button {
    //   height: 0.76rem;
    //   background: #fff;
    //   border-radius: 0;
    //   color: #24bbd3;
    //   width: 50%;
    //   margin: 0 !important;
    // }
    // .el-button.is-disabled {
    //   color: #ccc;
    // }
    display: flex;
    font-size: 0.28rem;
    line-height: 0.32rem;
    color: #24bbd3;
    justify-content: center;
    height: 0.76rem;
    align-items: center;
    div {
      width: 2rem;
      text-align: center;
    }
    img {
      width: 0.28rem;
      height: 0.28rem;
    }
  }
  .begin {
    text-align: center;
    margin: 0.12rem 0;
    color: #fff;
  }

  .van-popup {
    box-sizing: border-box;
    height: 2.83rem;
    padding: 0 0.16rem;

    .popup-title {
      font-size: 0.18rem;
      font-weight: 500;
      text-align: center;
      color: #092020;
      margin-bottom: 0.24rem;
    }

    .slider-wrap {
      width: 3.32rem;
      height: 0.69rem;
      margin: 0 auto 0.08rem;
      padding: 0;

      .van-slider {
        padding: 0 0.17rem;
        margin: 0.29rem 0 0.02rem;
      }

      .split-line {
        top: 0;
        left: 0;
        padding: 0 0.33rem;
        justify-content: space-between;
      }

      .marks {
        padding: 0 0.05rem 0 0.12rem;

        .marks-text {
          font-size: 0.18rem;
          line-height: 0.32rem;
        }
      }
    }

    .tips {
      padding: 0 0.055rem;
      display: flex;
      justify-content: space-between;
      font-size: 0.1rem;
      color: rgba(61, 61, 61, 0.4);
      margin-bottom: 0.4rem;
    }

    .van-button {
      width: 3.43rem !important;
      height: 0.48rem;
    }
  }

  .pay-popup {
    height: 2.16rem;

    .line {
      width: 0.3rem;
      height: 0.04rem;
      margin: 0.1rem auto;
      border-radius: 0.1rem;
      background-color: #d8d8d8;
    }

    .van-cell {
      height: 0.56rem;
      padding: 0 0.16rem;
      align-items: center;

      > img {
        width: 0.24rem;
        margin-right: 0.16rem;
      }
    }

    .van-button {
      width: 3.43rem !important;
      height: 0.48rem;
      border-radius: 0.4rem;
      margin: 0.13rem auto 0;

      .van-button__text {
        font-weight: 700;
      }
    }
  }
}
</style>