<template>
  <div class="RedeemCode">  
    <van-popup v-model="$parent.RedeemCodeDialog" position="bottom">
      <div class="line"></div>
      <div  class="redeem-box">
        <div class="title">
          <img src="@/assets/img/redeem_code.svg" alt="">
          <span>兑换码支付</span>
        </div>
        <input type="text" v-model="redeemCode" placeholder="请输入兑换码"/>
        <el-button type="primary" @click="pay()">兑换</el-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { payByRedeemCode } from "@/api/getData"
export default {
  name: "RedeemCode",
  props: ["prescriptionId"],
  data() {
    return {  
      redeemCode:''  
    }
  },
  methods: {    
     //兑换码兑换
     pay(){
      if(!this.redeemCode){
        this.$message({type: 'error', message: '请输入兑换码!'})
        return false;
      }
      payByRedeemCode(this.prescriptionId,this.redeemCode).then(res=>{
         this.$message.success('兑换成功')
         this.$emit("loadData");
      })
     },

     toPreList(){
      //跳转到处方列表
      //this.$router.push({path: "/"})
    } 
    
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.RedeemCode {
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  .van-popup {
    height: 2.68rem;

    .line {
      width: 0.3rem;
      height: 0.04rem;
      margin: 0.1rem auto 0.48rem;
      border-radius: 0.1rem;
      background-color: #d8d8d8;
    }

    .redeem-box {
      text-align: center;

      .title {
        height: 0.24rem;
        margin-bottom: 0.32rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 0.24rem;
          margin-right: 0.1rem;
        }

        span {
          font-size: 0.14rem;
          color: #092020;
        }
      }

      input {
        box-sizing: border-box;
        width: 2.76rem;
        height: 0.64rem;
        font-size: 0.18rem;
        font-weight: 500;
        text-align: center;
        border-radius: 0.04rem;
        border: 0.02rem solid #3194E2;
        margin-bottom: 0.24rem;
      }

      .el-button {
        width: 1.76rem;
        font-weight: 700;
      }
    }
  }
}
</style>