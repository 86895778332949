<template>
    <div>
        <div class="title">1. 请画出您疼痛的部位。</div>
        <div class="canvas-bg" >
            <canvas ref="canvas" @touchstart="startDraw" @touchmove="draw"  width="430" height="450" ></canvas>
        </div>
      <van-button type="info" @click="uploadImage">下载图片</van-button>
      <br/><br/>
      <van-button type="info" @click="showImage">查看图片</van-button>
      <van-popup v-model="showPopup" style="padding: '64px';">
        <div class="canvas-bg" >
           <img src="@/assets/canvasimage.png" />
        </div>
      </van-popup>
    </div>
  </template>
   
  <script>
  export default {
    data() {
      return {
        isDrawing: false,
        lastX: 0,
        lastY: 0,
        context: null,
        showPopup:false
      };
    },
    mounted() {
      this.context = this.$refs.canvas.getContext('2d');
      this.context.strokeStyle = "#1989fa"
    },
    methods: {
        showImage(){
            this.showPopup = true
        },  
      startDraw(e) {
        this.isDrawing = true;
        const touch = e.touches[0];
        const { x, y } = this.getTouchPos(touch, this.$refs.canvas);
        this.lastX = x;
        this.lastY = y;
      },
      draw(e) {
        if (this.isDrawing) {
          e.preventDefault();
          const touch = e.touches[0];
          const { x, y } = this.getTouchPos(touch, this.$refs.canvas);
          this.context.beginPath();
          this.context.moveTo(this.lastX, this.lastY);
          this.context.lineTo(x, y);
          this.context.stroke();
          this.lastX = x;
          this.lastY = y;
        }
      },
      getTouchPos(touch, canvas) {
        const rect = canvas.getBoundingClientRect();
        return { x: touch.clientX - rect.left, y: touch.clientY - rect.top };
      },
      uploadImage() {
        const image = this.$refs.canvas.toDataURL('image/png');
        // const formData = new FormData();
        // formData.append('image', this.dataURLtoBlob(image), 'image.png');
        // 使用Axios或者其他HTTP库发送formData到后端
        // axios.post('your-backend-url', formData).then(response => {
        //   console.log(response);
        // });
        // 创建一个URL指向Blob对象
        const url = URL.createObjectURL(this.dataURLtoBlob(image));
 
        // 创建一个a标签用于下载
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'canvasimage.png'); // 指定下载文件名
        document.body.appendChild(link);

        // 触发a标签的点击事件进行下载
        link.click();

        // 清理
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },
      dataURLtoBlob(dataurl) {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .title{
    padding: .05rem;
    font-size: .2rem;
  }
  .canvas-bg {
    width: 100%;
    height: 4rem;
    background: url(../../assets/img/manikin.jpg) no-repeat;
    background-size: 100% auto;
  }
  </style>  