import Vue from 'vue'
import Vuex from 'vuex'
import {
  setStore,
  getStore,
  clearStore
} from '@/utils/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vToken: getStore({
      name: 'vToken'
    }) || '',
    vUser: getStore({
      name: 'vUser'
    }) || '',
    vVerifycode: getStore({
      name: 'vVerifycode'
    }) || '',
    vUserId: getStore({
      name: 'vUserId'
    }) || '',
    wechatId: getStore({
      name: 'wechatId'
    }) || '',
    wechatImg: getStore({
      name: 'wechatImg'
    }) || '',
    username: getStore({
      name: 'username'
    }) || '',
    vOrgData: getStore({
      name: 'vOrgData'
    }) || ''
  },
  mutations: {
    SET_WECHATID: (state, wechatId) => {
      state.wechatId = wechatId;
      setStore({
        name: 'wechatId',
        content: state.wechatId
      })
    },
    SET_WECHATIMG: (state, wechatImg) => {
      state.wechatImg = wechatImg;
      setStore({
        name: 'wechatImg',
        content: state.wechatImg
      })
    },
    SET_vUserId: (state, vUserId) => {
      state.vUserId = vUserId;
      setStore({
        name: 'vUserId',
        content: state.vUserId
      })
    },
    SET_vUsername: (state, username) => {
      state.username = username;
      setStore({
        name: 'username',
        content: state.username
      })
    },
    SET_vToken: (state, vToken) => {
      state.vToken = vToken;
      setStore({
        name: 'vToken',
        content: state.vToken
      })
    },
    SET_vUser: (state, vUser) => {
      state.vUser = vUser;
      setStore({
        name: 'vUser',
        content: state.vUser
      })
    },
    SET_vOrgData: (state, vOrgData) => {
      state.vOrgData = vOrgData;
      setStore({
        name: 'vOrgData',
        content: state.vOrgData
      })
    },
    SET_vVerifycode: (state, vVerifycode) => {
      state.vVerifycode = vVerifycode;
      setStore({
        name: 'vVerifycode',
        content: state.vVerifycode
      })
    },
    Logout: (state) => {
      state.vToken = state.vVerifycode =  state.vUserId = state.wechatId = ''
      clearStore();
    },
  },
  actions: {
    // Logout() {
    //   console.log("store Logout")
    //   clearStore();
    // }
  },
  getters: {
    wechatId: state => state.wechatId,
    wechatImg: state => state.wechatImg,
    username: state => state.username,
    vToken: state => state.vToken,
    vVerifycode: state => state.vVerifycode,
    vOrgData: state => state.vOrgData,
    vUserId: state => state.vUserId
  },
  modules: {
  }
})
