<template>
  <div class="wechatPay">
    <van-popup v-model="$parent.WechatDialog" position="bottom">
      <div class="line"></div>
      <h2>{{payData.productName}}</h2>
      <div class="snCode">订单号：{{payData.sn}}</div>
      <div class="foot">
        <div class="price"><span><span>¥</span>{{payData.total}}</span><s>原价¥{{payData.originalPrice}}</s></div>
        <van-button block round type="info" @click="wechatPayFunction()">支付</van-button>
      </div> 
    </van-popup>   
  </div>

</template>
<script>
import { payWechat, wechatNotify} from "@/api/getData"
import wx from 'weixin-js-sdk'
import {Loading } from 'element-ui'
export default {
  name: "WechatPay",
  props: ["prescriptionId"],
  data() {
    return {
      payData: {}
    }
  },
  methods: {
    toPreList(){
      //跳转到处方列表
      this.$router.push({path: "/"})
    }, 
    wechatPayment() {  
      payWechat(this.prescriptionId).then(res => {
        this.payData = res.data;
        if (this.payData.isPay) {
          this.$router.push("/")
        }
      })
    },
    // 微信公众号内微信支付方法
    async wechatPayFunction() {
      let that = this;
      // 配置config信息
      wx.config({
        debug: false,
        appId: that.payData.start.appId, // 必填，公众号的唯一标识
        timestamp: that.payData.start.timestamp, // 必填，生成签名的时间戳
        nonceStr: that.payData.start.nonceStr, // 必填，生成签名的随机串
        signature: that.payData.start.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'chooseWXPay'] // 必填，需要使用的JS接口列表
      });

      // 通过ready接口处理成功验证
      wx.ready(function () {
        wx.checkJsApi({
          jsApiList: ['chooseWXPay'],
          success: function (res) {
            console.log('微信config检验成功', res);
          }
        });
        let payLoad = null
        wx.chooseWXPay({
          timestamp: that.payData.start.timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr: that.payData.start.nonceStr, // 支付签名随机串，不长于 32 位
          package: that.payData.pay.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: that.payData.pay.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: that.payData.pay.paySign, // 支付签名
          success: function (res) {  // 支付成功后的回调函数
            payLoad = Loading.service({
              lock: true,
              text: '支付结果加载中...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            })            
            let notifyData = {
              paymentId: that.payData.paymentId,
              timestamp: that.payData.start.timestamp,
              nonceStr: that.payData.start.nonceStr,
              packageStr: that.payData.pay.packageStr,
              paySign: that.payData.pay.paySign
            };
            let timerIndex = 0;
            let timerVal = setInterval(() => {
              wechatNotify(notifyData).then(res => {
                if (res.data) {
                  clearInterval(timerVal)
                  payLoad.close();
                  that.$emit("loadData")
                  that.$message.success('支付成功');
                  // that.$router.push({
                  //   path: "/",
                  //   query: {
                  //     preId: this.prescriptionId
                  //   }
                  // })
                  //that.steps = 1;
                }
              })
              timerIndex++;
              //超过三次还未成功 则跳到我的健康页面
              if (timerIndex == 3) {
                clearInterval(timerVal)
                //that.$router.push("/")
                this.$emit("loadData");
              }
            }, 5000);
          },
          fail: function (res) {
            that.$message.error('支付失败');
          }
        });

      });
    }
  },
  mounted() {
    this.wechatPayment();
  }
}
</script>

<style lang="scss" scoped>
.wechatPay {
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  .van-popup {
    height: 2.16rem;

    .line {
      width: 0.3rem;
      height: 0.04rem;
      margin: 0.1rem auto 0.3rem;
      border-radius: 0.1rem;
      background-color: #d8d8d8;
    }

    h2 {
      line-height: 0.32rem;
      font-size: 0.18rem;
      font-weight: 500;
      margin: 0 0 0.16rem 0.16rem;
    }

    .snCode {
      line-height: 0.24rem;
      font-size: 0.14rem;
      color: rgba(9, 32, 32, 0.4);
      margin: 0 0 0.24rem 0.16rem;
    }

    .foot {
      box-sizing: border-box;
      width: 100%;
      height: 0.76rem;
      padding: 0 0.16rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: -0.04rem -0.02rem 0.1rem -0.02rem rgba(30, 150, 150, 0.18);

      .price {
        span {
          font-size: 0.28rem;
          font-weight: 500;
          color: #fa5151;

          span {
            font-size: 0.12rem;
            font-weight: 400;
          }
        }

        s {
          margin-left: 0.08rem;
          font-size: 0.12rem;
          color: #a4aeae;
        }
      }

      .van-button {
        width: 1.76rem !important;
        height: 0.48rem;

        .van-button__text {
          font-weight: 700;
        }
      }
    }
  }
}
</style>