<template>
  <div class="preDetail">
    <div class="bgBox">
      <img :src="detailData.imgUrl" class="detailBg">
      <img src="@/assets/img/bgShadow.png" class="bgShadow">
    </div>
    <div class="detailDes">
      <div class="titleBox">
        <h2 class="title">{{detailData.title}}</h2>
        <p class="label">{{detailData.typeName}}</p>
      </div>
      <div class="detailNum">
        <div v-if="detailData.payStatus !== 2" class="price">
          <span><span>¥</span>{{ detailData.price }}</span><s>原价¥{{ detailData.originalPrice }}</s>
        </div>
        <div class="numBox">
          <p class="num"><b>{{detailData.period}}</b> 周</p>
          <p class="numTitle">训练周期</p>
        </div>
        <div class="numBox">
          <p class="num"><b>{{detailData.freqWeekly}}</b> 次</p>
          <p class="numTitle">训练频次</p>
        </div>
      </div>
      <p class="remark" :class="textMore ? 'remark': 'remark textHidden'">{{detailData.remark}}</p>
      <p class="more" @click="showMore">{{textMore ? '收起' : '点击查看更多'}}</p>
    </div>
    <div class="videoList" v-if="detailData.videoVOS">
      <div class="viedoDetail" v-for="(item,i) in detailData.videoVOS" :key="i" @click="toVideoDetail(item.id)">
        <div class="picBox">
          <img :src="item.imgUrl" class="pic">
          <img src="@/assets/img/playIcon.png" class="playIcon">
        </div>
        <div class="videoInf">
          <h3>{{item.name}}</h3>
          <div class="inf">
            <span>{{item.motionNum}}次</span>
            <span>{{item.groupNum}}组</span>
            <span>{{item.groupInterval}}秒</span>
          </div>
          <div class="label">
            <span>{{switchPartType(item.partTypeId)}}</span>
            <span>{{switchActionType(item.actionTypeId)}}</span>
          </div>
        </div>
      </div>
      <div v-if="detailData.payStatus == 0" class="paid-unlocking-box">
        <img src="@/assets/img/paid-unlocking.webp" alt="" class="paid-unlocking">
        <el-button @click="payPre" class="pay-btn">支付解锁更多训练</el-button>
      </div>
    </div>
    <div class="begin">
      <el-button @click="toVideoDetail(detailData.videoVOS[0].id)">开始训练</el-button>
    </div>
     <!-- 支付对话框 -->
     <van-popup v-model="payTypeDialog" v-if="payTypeDialog" position="bottom">
      <div class="line"></div>
      <van-radio-group v-model="payType">
        <van-cell-group>
          <van-cell title="兑换码支付" clickable @click="payType = 'redeemCode'">
            <template #icon>
              <img src="@/assets/img/redeem_code.svg" alt="">
            </template>
            <template #right-icon>
              <van-radio name="redeemCode" />
            </template>
          </van-cell>
          <van-cell title="微信支付" clickable @click="payType = 'wechat'">
            <template #icon>
              <img src="@/assets/img/wechat.svg" alt="">
            </template>
            <template #right-icon>
              <van-radio name="wechat" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-button block type="primary" @click="toPay">下一步</van-button>
    </van-popup>
    <WechatDialog ref="WechatDialog" @loadData="loadDetail" :prescriptionId="prescriptionId" v-if="WechatDialog" ></WechatDialog>
    <RedeemCodeDialog ref="RedeemCodeDialog" @loadData="loadDetail" :prescriptionId="prescriptionId" v-if="RedeemCodeDialog" ></RedeemCodeDialog>
  </div>
</template>
<script>
import { 
  prescriptionDetail,
  getActionPartsHttp,
  getActionModesHttp
} from '@/api/getData'
import WechatDialog from "@/views/Pay/WechatPay.vue";
import RedeemCodeDialog from "@/views/Pay/RedeemCode.vue";
export default {
  name: "PrescriptionDetail",
  components: { WechatDialog,RedeemCodeDialog},
  data() {
    return {
      detailData: {},
      textMore: false,      
      payType:'wechat',
      payTypeDialog:false,
      prescriptionId:'',
      payData: {},
      WechatDialog:false,
      RedeemCodeDialog:false,
      actionPartData:[],
      actionModeData:[],
    }
  },
  methods: {
    //跳转视频详情
    toVideoDetail(id) {
      this.$router.push({
        path: "/prescription-train-video",
        query: {
          videoId: id,
          preId: this.$route.query.preId
        }
      })
    },
    //部位转换
    switchPartType(partTypeId) {
      for (var i in this.actionPartData) {
        if (partTypeId === this.actionPartData[i].value) {
          return this.actionPartData[i].msg;
        }
      }
      return "-";
    },
    //动作转换
    switchActionType(actionTypeId) {
      for (var i in this.actionModeData) {
        if (actionTypeId === this.actionModeData[i].value) {
          return this.actionModeData[i].msg;
        }
      }
      return "-";
    },
    //获取运动方式
    getActionModes(){
       getActionModesHttp().then(({data})=>{
          this.actionModeData = data
       })
    },
    //获取训练部位
    getActionParts(){
      getActionPartsHttp().then(({data})=>{
          this.actionPartData = data
       })
    },
     //去支付
     toPay(){
      if(this.payType === 'redeemCode'){//兑换码
        this.payTypeDialog = false
        this.RedeemCodeDialog = true

      }else if(this.payType === 'wechat'){//微信支付
        this.payTypeDialog = false
        this.WechatDialog = true
      }
    },
    payPre(){
      this.payTypeDialog = true
    },
    //展示更多备注
    showMore() {
      this.textMore = !this.textMore
    },
    loadDetail(){
      this.payTypeDialog = false
      this.RedeemCodeDialog = false
      this.WechatDialog = false
      this.getDetail()
    },
    //获取资料标签
    getDetail() {      
      prescriptionDetail(this.prescriptionId).then(res => {
        this.detailData = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    goBack() {
      this.$router.replace('/prescription-list')
    },
  },
  mounted() {
    this.getActionModes()
    this.getActionParts()
    this.prescriptionId = this.$route.query.preId;
    this.getDetail()
    
  }
}
</script>

<style lang="scss" scoped>
.preDetail {
  background: url(../../assets/img/bg_pic.png) no-repeat 0 1.75rem;
  background-size: 100% auto;
  overflow: hidden;
  .bgBox {
    width: 100%;
    height: 2.4rem;
    position: relative;
    .detailBg {
      display: block;
      width: 100%;
      height: 100%;
    }
    .bgShadow{
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .detailDes {
    width: 3.43rem;
    margin: -0.88rem auto 0;
    position: relative;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(8, 131, 131, 0.09);
    padding-bottom: 0.16rem;
    .titleBox {
      display: flex;
      justify-content: space-between;
      padding: 0 0.16rem;
      height: 0.48rem;
      border-bottom: 1.5px solid #f5f5f5;
      align-items: center;
      .title {
        line-height: 0.48rem;
      }
      .label {
        display: block;
        height: 0.3rem;
        line-height: 0.3rem;
        background: rgba(36, 187, 211, 0.06);
        padding: 0 0.08rem;
        font-size: 0.14rem;
        color: #24bbd3;
      }
    }
    .detailNum {
      display: flex;
      margin-left: 0.16rem;
      padding: 0.24rem 0;
      .numBox {
        margin-left: 0.4rem;
        .num {
          font-size: 0.1rem;
          color: #24bbd3;
          b {
            font-size: 0.28rem;
          }
        }
        .numTitle {
          font-size: 0.1rem;
          color: #a8b0b0;
          margin-top: 0.12rem;
        }
      }
      .price {
        span {
          font-size: 0.24rem;
          font-weight: 500;
          color: #fa5151;
          span {
            font-size: 0.12rem;
          }
        }
        s {
          font-size: 0.12rem;
          margin-left: 0.08rem;
        }
      }
    }
    .remark {
      font-size: 0.14rem;
      line-height: 0.24rem;
      color: #9da6a6;
      margin: 0 0.16rem;
    }
    .textHidden {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 0.48rem;
    }
    .more {
      margin-top: 0.16rem;
      margin-left: 0.16rem;
      font-size: 0.14rem;
      line-height: 0.24rem;
      color: #24bbd3;
    }
  }
  .videoList {
    margin-top: 0.16rem;
    .viedoDetail {
      display: flex;
      padding: 0.12rem 0.16rem;
      background: #fff;
      border-bottom: 1px solid rgba(216, 216, 216, 0.2);
      .picBox {
        width: 1.4rem;
        height: 0.88rem;
        position: relative;
        margin-right: 0.16rem;
        .pic {
          display: block;
          width: 100%;
          height: 100%;
        }
        .playIcon {
          display: block;
          width: 0.5rem;
          height: 0.5rem;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -0.25rem;
          margin-top: -0.25rem;
        }
      }
      .videoInf {
        h3 {
          font-size: 0.18rem;
          line-height: 0.32rem;
          font-weight: bold;
        }
        .inf {
          margin: 0.05rem 0 0.1rem;
          span {
            color: rgba(61, 61, 61, 0.7);
            font-size: 0.14rem;
            margin-right: 0.16rem;
          }
        }
        .label {
          span {
            display: inline-block;
            margin-right: 0.1rem;
            padding: 8px;
            border-radius: 4px;
            background: rgba(36, 187, 211, 0.06);
            color: #24bbd3;
            font-size: 0.14rem;
            line-height: 1;
          }
        }
      }
    }
  }
  .paid-unlocking-box {
    position: relative;

    .paid-unlocking {
      display: block;
      width: 100%;
    }

    .pay-btn {
      width: 1.38rem;
      height: 0.3rem;
      padding: 0;
      box-sizing: border-box;
      border-radius: 0.3rem;
      position: absolute;
      top: 1.16rem;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.14rem;
      color: #3194e2;
      background: rgba(49, 148, 226, 0.1);
      border: 0.015rem solid rgba(50, 150, 227, 0.4);
    }
  }
  .begin {
    text-align: center;
    width: 100%;
    margin-top: 0.27rem;
    padding: 0.14rem 0;
    box-shadow: -0.04rem -0.02rem 0.10rem -0.02rem rgba(30, 150, 150, 0.18);
  }
}
.van-popup {
    height: 2.16rem;

    .line {
      width: 0.3rem;
      height: 0.04rem;
      margin: 0.1rem auto;
      border-radius: 0.1rem;
      background-color: #d8d8d8;
    }

    .van-cell {
      height: 0.56rem;
      padding: 0 0.16rem;
      align-items: center;

      > img {
        width: 0.24rem;
        margin-right: 0.16rem;
      }
    }

    .van-button {
      width: 3.43rem !important;
      height: 0.48rem;
      border-radius: 0.4rem;
      margin: 0.13rem auto 0;

      .van-button__text {
        font-weight: 700;
      }
    }
  }
</style>