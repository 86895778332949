import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "../views/Home/Home";  //首页，存放公共tabbar
import Appver from "../views/Appver/Appver";	//版本
import LoginSms from '../views/Login/LoginSms.vue'	//登录
import Supplement from '../views/User/Supplement.vue'	//完善个人信息
import Joint from '../views/User/Joint.vue'	//选择关节
import PrescriptionList from '../views/Prescription/List.vue'      //处方列表
import PrescriptionDetail from '../views/Prescription/Detail.vue'	//处方详情	
import PrescriptionTrainVideo from '../views/Prescription/TrainVideo.vue'		//视频详情
import Canvas from '../views/canvas/index.vue'		
import Recorder from '../views/recorder/index.vue'		
import store from '../store'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/prescription-list',
		children: [
			{
				path: '/prescription-list',
				name: 'PrescriptionList',
				component: PrescriptionList,
				meta: {
					title: '我的训练'
				}
			},
			{
				path: '/prescription-detail',
				name: 'PrescriptionDetail',
				component: PrescriptionDetail,
				meta: {
					title: '训练详情',
					hideTab: true
				}
			},
			{
				path: '/prescription-train-video',
				name: 'PrescriptionTrainVideo',
				component: PrescriptionTrainVideo,
				meta: {
					requireAuth: true,
					title: '视频详情',
					hideTab: true
				}
			},	
			{
				path: '/joint',
				name: 'Joint',
				component: Joint,
				meta: {
					requireAuth: true,
					title: '选择疼痛部位'
				}
			},
			{
				path: '/par-q',
				name: 'ParQ',
				component: () => import('../views/ParQ/Index'),
				meta: {
					requireAuth: true,
					title: 'PAR-Q问卷',
					hideTab: true
				}
			},
			{
				path: '/hip-q',
				name: 'HipQ',
				component: () => import('../views/HipQ/Index'),
				meta: {
					requireAuth: true,
					title: 'HOOS-12髋关节调查问卷',
					hideTab: true
				}
			},
			{
				path: '/knee-q',
				name: 'KneeQ',
				component: () => import('../views/KneeQ/Index'),
				meta: {
					requireAuth: true,
					title: 'KOOS-12膝关节调查问卷',
					hideTab: true
				}
			},
			{
				path: '/back-q',
				name: 'BackQ',
				component: () => import('../views/BackQ/Index'),
				meta: {
					requireAuth: true,
					title: '腰背调查问卷',
					hideTab: true
				}
			},
			{
				path: '/user',
				name: 'user',
				component: () => import('../views/User/Index'),
				meta: {
					requireAuth: true,
					title: '个人中心'
				}
			},
			{
				path: '/edit',
				name: 'Edit',
				component: () => import('@/views/User/Edit'),
				meta: {
					requireAuth: true,
					title: '个人中心',
					hideTab: true
				}
			},
			{
				path: '/success',
				name: 'Success',
				component: () => import('../views/User/Success'),
				meta: {
					requireAuth: true,
					title: '操作成功页面',
					hideTab: true
				}
			}
	]
	},
	{
		path: '/login-sms',
		name: 'LoginSms',
		component: LoginSms,
		meta: {
			title: '短信登录'
		}
	},
	{
		path: '/supplement',
		name: 'Supplement',
		component: Supplement,
		meta: {
			requireAuth: true,
			title: '完善个人信息'
		}
	},
	{
		path: '/canvas',
		name: 'canvas',
		component: Canvas,
		meta: {
			title: '画图'
		}
	},
	{
		path: '/recorder',
		name: 'recorder',
		component: Recorder,
		meta: {
			title: '语音'
		}
	},
	{
		path: '/appver',
		name: 'Appver',
		component: Appver,
		meta: {
			title: 'Appver'
		}
	}
];

const router = new VueRouter({
	mode:'history',
	routes
});

//页面打开之前
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	if (to.meta.requireAuth) {
		if (store.state.vToken) {			
			next()
		} else {
			next('/login-sms')
		}
	} else {		
		next()
	}
});

//router path重复异常处理
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

export default router;