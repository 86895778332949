<template>
  <div class="supplement">
    <h2>完善个人信息</h2>
    <van-form @submit="updateUser">
      <van-field
          v-model="ruleForm.name"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{ required: true }]"
      />
      <van-field
          readonly
          clickable
          name="picker"
          :value="ruleForm.gender===1?'男':'女'"
          label="性别"
          right-icon="arrow"
          placeholder="请选择性别"
          @click="showGender = true"
      />
      <van-popup v-model="showGender" position="bottom">
        <van-picker
            show-toolbar
            :columns="genderData"
            @confirm="changeGender"
            @cancel="showGender = false"
        />
      </van-popup>
      <van-field
          readonly
          clickable
          name="calendar"
          :value="ruleForm.birthday"
          label="出生日期"
          right-icon="arrow"
          placeholder="请选择出生日期"
          :rules="[{ required: true }]"
          @click="showPopup"
      />
      <van-popup v-model="showCalendar" position="bottom">
        <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择出生日期"
            :min-date="minDate"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="changeBirthday"
        />
      </van-popup>
      <van-field
          v-model.number="ruleForm.height"
          type="number"
          name="身高"
          label="身高(cm)"
          placeholder="请输入身高"
          :rules="[{ required: true }]"
      />
      <van-field
          v-model.number="ruleForm.weight"
          type="number"
          name="体重"
          label="体重(kg)"
          placeholder="请输入体重"
          :rules="[{ required: true }]"
      />
      <van-field
          v-model="ruleForm.idCard"
          name="身份证"
          label="身份证"
          placeholder="请输入身份证号码"
          :rules="[{ required: true }]"
      />
      <!-- <van-field
          readonly
          clickable
          name="picker"
          :value="orgName"
          label="所属机构"
          right-icon="arrow"
          placeholder="请选择所属机构"
          @click="showOrgId = true"
      />
      <van-popup v-model="showOrgId" position="bottom">
        <van-picker
            show-toolbar
            :columns="orgData"
            @confirm="changeOrgId"
            @cancel="showOrgId = false"
        />
      </van-popup> -->
      <van-button round block type="info" native-type="submit">下一步</van-button>
    </van-form>
  </div>
</template>
<script>
import { updateUser } from "@/api/getData"
import { validator } from "@/utils/validate"

export default {
  name: "Supplement",
  data() {
    return {
      ruleForm: {
        name: "",
        gender: 1,
        birthday: '',
        height: '',
        weight: '', 
        idCard:'',//身份证号码
        //orgId: 14 //所属机构 默认机构为 WTOA 可修改
      },
      orgData: [],
      orgName: '',
      showGender: false,
      showOrgId: false,
      currentDate: new Date(2000, 0, 1),
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      genderData: [
        {
          text: '男',
          value: 1
        },
        {
          text: '女',
          value: 0
        }
      ],
      showCalendar: false,
    }
  },
  methods: {
    //orgAll 所有机构数据
    orgAll() {
      orgAll().then(res => {
        this.orgData = res.data.map(item => {
          return {text: item.name, value: item.id}
        })
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].id === this.ruleForm.orgId) {
            this.orgName = res.data[i].name
            return
          }
        }
      })
    },
    //身高体重验证
    validate(val) {
      return val < 1 || val > 300
    },
    //身份证号码验证
    idCard(val) {
      return validator(val)
    },
    //选择性别，关闭弹窗
    changeGender(data) {
      this.ruleForm.gender = data.value
      this.showGender = false
    },
    //选择所属机构，关闭弹窗
    changeOrgId(data) {
      this.ruleForm.orgId = data.value
      for (var i = 0; i < this.orgData.length; i++) {
        if (this.orgData[i].value === this.ruleForm.orgId) {
          this.orgName = this.orgData[i].text
          this.showOrgId = false
          return
        }
      }
    },
    //点击弹出日期选择器，并设置初始值
    showPopup() {
      if (this.ruleForm.birthday) {
        this.currentDate = new Date(this.ruleForm.birthday)
      }
      this.showCalendar = true
    },
    //日期格式化处理
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`
      }
    },
    //选择出生日期
    changeBirthday(date) {
      let M = date.getMonth() + 1, d = date.getDate()
      M = M < 10 ? '0' + M : M
      d = d < 10 ? '0' + d : d
      this.ruleForm.birthday = `${date.getFullYear()}-${M}-${d}`
      this.showCalendar = false
    },
    //完善个人信息
    updateUser() {
      if (this.validate(this.ruleForm.height)) {
        return this.$message.error('请输入正确的身高')
      }
      if (this.validate(this.ruleForm.weight)) {
        return this.$message.error('请输入正确的体重')
      }
      if (!validator(this.ruleForm.idCard)) {
        return this.$message.error('请输入正确的身份证号码')
      }
      updateUser(this.vUserId, this.ruleForm).then(() => {
        this.$store.commit('SET_vUsername', this.ruleForm.name)
        this.$message.success('操作成功')
        this.$router.push({
          path: "/prescription-list"
        })
      }).catch(error => {
        console.log(error)
      })
    },
  },
  mounted() {
    if(this.username){                     
      this.$router.push({path: "/user"})          
    }
  //  this.orgAll()
    // if(this.vOrgData){
    //     this.ruleForm.orgId=this.vOrgData.id
    // }
  }
}
</script>

<style lang="scss" scoped>
.supplement {
  overflow: hidden;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  h2 {
    font-size: 0.18rem;
    line-height: 1;
    margin: 0.4rem 0 0.4rem 0.18rem;
  }

  :deep(.van-form) {
    .van-cell {
      font-size: 0.14rem;
      height: 0.56rem;
      line-height: 0.56rem;
      padding: 0.12rem 0;

      &::after {
        left: 0;
        right: 0;
      }

      .van-field__label {
        width: 0.6rem;
        line-height: 0.32rem;
        margin: 0 0.32rem 0 0.16rem;
        color: #323233;
      }

      .van-field__control {
        width: 2.25rem;
        height: 0.32rem;
        line-height: 0.32rem;
      }

      .van-field__right-icon {
        width: 0.3rem;
        height: 0.3rem;
        padding: 0;
        text-align: center;
        line-height: 0.3rem;

        .van-icon {
          font-size: 0.16rem;
        }
      }
    }
  }

  .van-button {
    width: 3.43rem !important;
    height: 0.47rem;
    margin: 0.4rem auto 0;
  }
}
</style>