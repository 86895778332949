<template>
  <div class="login">
    <img src="@/assets/img/login_bg.svg" alt="" class="login-bg">
    <div class="logo-wrap">
      <img src="@/assets/img/logo.svg" alt="" class="logo">
      <div>{{vOrgData?vOrgData.name:'闻涛运动康复'}}</div>
    </div>
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item prop="mobile">
        <el-input v-model="ruleForm.mobile" @focus="show=false" @blur="show=true" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item prop="captchaCode" class="captcha-wrap">
        <el-input v-model="ruleForm.captchaCode" @focus="show=false" @blur="show=true" placeholder="请输入图形验证码"></el-input>
        <img :src="'data:image/jpeg;base64,'+imgSrc" @click="refreshImg" alt="" class="captcha">
      </el-form-item>
      <el-form-item prop="smsCode" class="captcha-wrap">
        <el-input v-model="ruleForm.smsCode" @focus="show=false" @blur="show=true" placeholder="请输入短信验证码"></el-input>
        <el-button @click="getCode" :disabled="timerTf" v-if="!timerTf">发送</el-button>
        <el-button v-else disabled>{{countDownNum}} S</el-button>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
  </div>
</template>
<script>
import { getCaptchaImage, sendLoginSms, LoginSms } from "@/api/getData"

export default {
  name: "LoginSms",
  data() {
    return {
      imgSrc: '',
      ruleForm: {
        mobile: '',
        captchaCode: '',
        smsCode: ''
      },
      show: true,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        captchaCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      timerTf: false,
      countDownNum: 120,
      timer: null
    }
  },
  methods: {
    //图形验证码
    getCaptchaImage() {
      getCaptchaImage().then(res => {
        this.imgSrc = res.data
      })
    },
    //刷新图形验证码
    refreshImg() {
      this.getCaptchaImage()
    },
    //获取短信验证码
    getCode() {
      let vKey = false;
      this.$refs.ruleForm.validateField(['captchaCode','mobile'], (val) => {
        if (val) {
          vKey = true;          
        } else {
          return false;
        }
      })
      if(!vKey){
        let data = {
            captchaKey: this.vVerifycode,
            captchaCode: this.ruleForm.captchaCode,
            mobile: this.ruleForm.mobile
          }
          sendLoginSms(data).then(res => {
            this.timerTf = true
            this.countDownNum = 120
            this.timer = setInterval(() => {
              this.countDownNum--
              if (this.countDownNum <= 0) {
                this.timerTf = false
                clearInterval(this.timer)
              }
            }, 1000)
            //this.ruleForm.smsCode = res.data.code
          })
      }
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            mobile: this.ruleForm.mobile,
            smsCode: this.ruleForm.smsCode,
            //wechatId: 'otbq76UkIwp7OnqRGClHqDWRvedo',
            wechatImg: this.wechatImg,
            wechatId: this.wechatId,
            orgId:this.vOrgData?this.vOrgData.id:14,
          }
          LoginSms(data).then(res => {
            this.$message.success('登录成功')
            this.$store.commit('SET_vToken', res.data.token)
            this.$store.commit('SET_vUserId', res.data.userId)
            this.$store.commit('SET_vUsername', res.data.name)
            this.$store.commit('SET_vOrgData', {id: res.data.orgId,name:''})
            if(res.data.name){
              this.$router.push({path: "/"})                       
            }else{
              this.$router.push({path: "/supplement"})
            }              
          })
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    if(this.username){                     
      this.$router.push({path: "/"})          
    }
    this.getCaptchaImage()
  }
}
</script>

<style lang="scss" scoped>
.login {
  overflow: hidden;

  .login-bg {
    display: block;
    width: 100%;
    margin-bottom: 1.51rem;
  }

  .logo-wrap {
    position: absolute;
    top: 1.29rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: .18rem;
    font-weight: 500;
    text-align: center;
    color: #ffffff;

    .logo {
      width: 1.02rem;
      margin-bottom: .15rem;
    }
  }

  :deep(.el-form) {
    width: 2.55rem;
    height: 1.79rem;
    box-sizing: border-box;
    padding: .2rem .2rem 0;
    position: absolute;
    top: 3.22rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: .2rem;
    background-color: #fff;
    box-shadow: 0 .04rem .1rem 0 rgba(0, 0, 0, 0.1);

    .el-form-item .el-form-item__content {
      line-height: 0.33rem;
    }

    .captcha-wrap {
      .el-input {
        width: 1.45rem;
      }

      .el-form-item__content {
        display: flex;
        justify-content: space-between;
      }
    }

    .el-input__inner {
      height: .33rem;
      line-height: .33rem;
      color: #092020;
    }
  }

  .captcha {
    width: .6rem;
  }

  .el-button--primary{
    width: 2.55rem;
    display: block;
    margin: 0 auto;
  }
  .el-button--default{
    width: .6rem;
    height: .32rem;
    padding: 0;
    margin: 0;
    font-size: .16rem;
    box-shadow: none;
    border-radius: 0.04rem;
  }
  .el-button--default.is-disabled {
    border: 0.01rem solid #24BBD3;
  }
  :deep(.el-checkbox__label) {
    font-size: 0.12rem;
    padding-left: 0.04rem;
    a {
      color: #24bbd3;
    }
  }
}
</style>