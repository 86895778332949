<template>
  <div class="Joint">
    <h2>选择疼痛部位</h2>
    <div class="position-wrap">
      <div :class="qName==='KneeQ'?'active':''">
        <input type="radio" v-model="qName" value="KneeQ">
        <img src="@/assets/img/knee_joint.svg" alt="" class="position-ico">
        <div>膝关节疼痛</div>
        <img src="@/assets/img/checked.svg" alt="" class="checked">
      </div>
      <div :class="qName==='HipQ'?'active':''">
        <input type="radio" v-model="qName" value="HipQ">
        <img src="@/assets/img/hip_joint.svg" alt="" class="position-ico">
        <div>髋关节疼痛</div>
        <img src="@/assets/img/checked.svg" alt="" class="checked">
      </div>
      <div :class="qName==='BackQ'?'active':''">
        <input type="radio" v-model="qName" value="BackQ">
        <img src="@/assets/img/back_pain.svg" alt="" class="position-ico">
        <div>腰背疼痛</div>
        <img src="@/assets/img/checked.svg" alt="" class="checked">
      </div>
      <div :class="qName==='Common'?'active':''">
        <input type="radio" v-model="qName" value="Common">
        <img src="@/assets/img/no_obvious_discomfort.svg" alt="" class="position-ico">
        <div>上班族脊柱不适</div>
        <img src="@/assets/img/checked.svg" alt="" class="checked">
      </div>
    </div>

    <el-button type="primary" @click="toQ()">开始测评</el-button>
  </div>
</template>
<script>
import { addCommonPre } from "@/api/getData"
export default {
  name: "Joint",
  data() {
    return {   
      qName:''  
    }
  },
  methods: {  
    
    toQ(){
      //跳转到上班族脊柱不适处方
      if(!this.qName){
        this.$message({type: 'error', message: '请选择疼痛部位!'});
      }else if(this.qName == 'Common'){
        this.toCommon()
      }else{
        //跳转到ParQ问卷
        this.$router.push({
          path: "/par-q",
          query: {
            qName: this.qName
          }
        })
      }
    },
    //设置上班族脊柱不适处方
    toCommon(){
      this.$confirm('确认选择上班族脊柱不适?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        addCommonPre(this.vUserId).then(res => {
          this.$message.success('已成功添加通用方案!');
          this.$router.push({path: "/",query: { preId: res.data}})
        })        
      }).catch(() => {
      });
    }  
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.Joint {
  padding: 0 0.16rem;
  overflow: hidden;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  h2 {
    padding: .4rem 0 .32rem;
  }

  .position-wrap {
    height: 3.43rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: space-between;
    margin-bottom: .24rem;

    > div {
      box-sizing: border-box;
      width: 1.68rem;
      height: 1.68rem;
      position: relative;
      font-size: .14rem;
      text-align: center;
      border-radius: 0.04rem;
      border: 0.02rem solid rgba(49, 148, 226, 0.08);
      background: rgba(49, 148, 226, 0.08);

      .position-ico {
        display: block;
        margin: 0 auto;
      }

      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 6;
      }

      .checked {
        width: .23rem;
        position: absolute;
        top: -0.01rem;
        right: -0.01rem;
        display: none;
      }
    }

    > div:nth-child(1) .position-ico {
      width: .5rem;
      margin-top: .26rem;
      margin-bottom: .11rem;
    }

    > div:nth-child(2) .position-ico {
      width: .57rem;
      margin-top: .38rem;
      margin-bottom: .10rem;
    }

    > div:nth-child(3) .position-ico {
      width: .7rem;
      margin-top: .36rem;
      margin-bottom: .14rem;
    }

    > div:nth-child(4) .position-ico {
      width: .73rem;
      margin-top: .33rem;
      margin-bottom: .18rem;
    }

    .active {
      font-weight: 700;
      color: #3194e2;
      border-color: #3194E2;

      .checked {
        display: block;
      }
    }
  }

  .el-button {
    width: 100%;
  }
}
</style>