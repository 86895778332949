import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/css/element-variables.scss'
import './utils/rem'
import { mixin } from '@/mixins'
import './utils/message'
import Vant from 'vant';
import 'vant/lib/index.css';
import vConsole from './utils/vconsole'
Vue.use(Vant);
Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.mixin(mixin);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
