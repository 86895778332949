import axios from 'axios'
import { Message, Loading } from 'element-ui'
import store from '@/store'
import router from "@/router"

const service = axios.create({
	//baseURL: 'http://192.168.2.208:8091/',
	// baseURL: 'https://management.noitom.com.cn/admin2019/nhealth/',
	// baseURL: 'http://127.0.0.1:8091',
	baseURL: `${process.env.VUE_APP_SERVER_URL}`,
	timeout: 50000
})

let loading = null

//http request拦截
service.interceptors.request.use(req => {
	loading = Loading.service({
		background: 'rgba(0, 0, 0, 0.7)'
	})
	if (store.state.vToken) {
		req.headers['x-token'] = store.state.vToken
	}
	return req
})

//http response拦截
service.interceptors.response.use(res => {
	loading.close()
	if (res.config.url == '/oa/public/captcha-image') {
		store.commit('SET_vVerifycode', res.headers.verifycode)
	} else if (res.status === 200) {
		const message = res.data.msg || '未知错误'
		if (res.data.code === 1) {
			store.commit('Logout');
			//router.replace("/login-sms");
			window.location.href = `${process.env.VUE_APP_CLIENT_URL}`;
			Message({
				message: message,
				type: 'error'
			});
			return Promise.reject(new Error(message))
		} else if (res.headers['content-type'] === 'application/octet-stream' && res.data.code === undefined) {
			return res
		} else if (res.data.code !== 0) {
			Message({
				message: message,
				type: 'error'
			});
			return Promise.reject(new Error(message))
		}
	}
	return res.data
})

export default service